import React, { useContext, useEffect } from "react";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { USER_STATUS } from "Enums";
import linkify from "Helpers/linkify";
import usePostLoginRedirect from "Hooks/usePostLoginRedirect";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { AuthContext } from "States/auth/authState";
import { ConfigContext } from 'States/config/configState';
import { Button } from "Components/shared/buttons";
import SimplePage from "Components/shared/SimplePage";
import { RefreshIcon, UserProfileIcon } from 'Components/shared/symbols';

const AccountVerification = () => {
  const redirect = usePostLoginRedirect();
  const { getStatus, status, loading } = useContext(AuthContext);
  const {
    config: {
      checkoutSettings,
      imprint
    },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (
      status === USER_STATUS.Approved ||
      status === USER_STATUS.Rejected ||
      status === USER_STATUS.Disabled
    ) {
      redirect({
        status,
        isEmailConfirmed: true,
          hideShop: checkoutSettings.hideShop
      });
    }
  }, [status]);

  return (
    <SimplePage
      header={i18nextTranslate(i18nextKeys.signupTitle)}
      icon={UserProfileIcon}
      title={i18nextTranslate(i18nextKeys.signupAdminTitle)}
      description={linkify(
        i18nextTranslateDynamically(
          i18nextKeys.signupAdminDescription,
          { email: imprint?.email }
        )
      )}
      dataQa="account-verification"
    >
      <Button
        dataQa="button-status"
        text={i18nextTranslate(i18nextKeys.signupAdminStatus)}
        icon={RefreshIcon}
        onClick={getStatus}
        className="flex-row-reverse mt-16"
        width={{
          xxl: '190px',
          default: '170px'
        }}
        disabled={loading}
        loading={loading}
      />
    </SimplePage>
  );
};

export default AccountVerification;
