import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { UiContext } from "States/ui/uiState";
import { Error, MemoizedValidationField } from "Components/shared/formElements";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import { TextButton } from "Components/shared/buttons";
import { AbortSymbol, WarningIcon } from "Components/shared/symbols";
import Text from "Components/shared/Text";
import { ProgressBar } from "Components/shared/Loading";

const FileUpload = ({
  accept = {},
  buttonText,
  buttonAction = async () => {},
  extension = "docx",
  fieldName,
  fieldNamePrefix,
  isDefaultLanguage = false,
  languageCode,
  loadingInitial = false,
  validate = () => {},
  validateAsync = async () => {},
  fieldWidth = "404px"
}) => {
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);
  const abortControllerRef = useRef(null);
  const formInputRef = useRef();
  const [serverValidationError, setServerValidationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const abortRequest = () => {
    if (abortControllerRef.current?.signal &&
      !abortControllerRef.current.signal.aborted
    ) {
      abortControllerRef.current.abort();
    }
  };

  useEffect(() => abortRequest, []);

  useEffect(() => {
    if (!loading && progress) {
      setProgress(0);
    }
  })

  const handleProgress = ({ lengthComputable, loaded, total }) => {
    const progress = lengthComputable
      ? Math.round((loaded / total) * 100)
      : 100;
    setProgress(progress);
  };

  const actionFn = useCallback(async () => {
    setServerValidationError(null);
    setLoading(true);
    const controller = new AbortController();
    abortControllerRef.current = controller;
    await buttonAction(
      abortControllerRef.current,
      handleProgress,
      languageCode,
      formInputRef.current.value
    );
    setLoading(false);
  });

  const validateFn = useCallback((template, values, meta) =>
    validate(template, values, meta, isDefaultLanguage), []);

  const validateAsyncFn = useCallback(async (template) => {
    setLoading(true);
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const result = await validateAsync(
      abortControllerRef.current,
      handleProgress,
      languageCode,
      template
    );
    setLoading(false);
    return result;
  }, []);

  const onDrop = (acceptedFiles) => {
    formInputRef.current.onChange(acceptedFiles[0]);
    formInputRef.current.onBlur();
  }

  const removeFile = () => {
    abortRequest();
    setServerValidationError(null);
    formInputRef.current.onChange(undefined);
    formInputRef.current.onBlur();
  };

  const hasError = ({ dirty, error }) => !progress && (
    loading || serverValidationError || (dirty && error)
  );

  const actionButton = buttonText ? (
    <TextButton
      text={buttonText}
      onClick={actionFn}
      dataQa={`file-action-${languageCode}`}
    />
  ) : null;

  const getFileExtention = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1];
  }

  return (
    <div
      className="bg-4_7"
      style={{
        width: fieldWidth,
        height: "70px"
      }}
    >
      {loadingInitial ? (
        <div className="flex justify-center items-center h-full">
          <LoadingSpinner
            size="h-40 w-40"
            dataQa="file-loading"
          />
        </div>
      ) : (
        <MemoizedValidationField
          name={`${fieldNamePrefix}.${fieldName}`}
          validate={validateFn}
          validateAsync={validateAsyncFn}
          validateFields={[]}
          subscription={{
            dirty: true,
            error: true,
            value: true,
          }}
        >
          {({ input, meta }) => {
            formInputRef.current = input;
            return (
              <>
                {input.value ? (
                  <div
                    className={`flex justify-between p-12 h-full ${
                      (serverValidationError || (meta.dirty && meta.error))
                        ? "border rounded border-color-red"
                        : ""
                    }`}
                  >
                    <div className="flex gap-16 justify-between">
                      <div
                        className="flex justify-center items-center rounded bg-3"
                        style={{ width: "44px" }}
                      >
                        {serverValidationError || (meta.dirty && meta.error) ? (
                          <WarningIcon
                            size="28px"
                            dataQa={`file-error-icon-${languageCode}`}
                          />
                        ) :
                          (progress || loading) ?
                            progress && progress < 100
                              ? <ProgressBar size="28" progress={progress} />
                              : <LoadingSpinner size="h-28 w-28" />
                          : (
                            <Text
                              textStyle="text-small"
                              dataQa={`file-preview-${languageCode}`}
                              uppercase
                            >
                              {getFileExtention(input.value.name || input.value)}
                            </Text>
                          )
                        }
                      </div>
                      <div className={`flex flex-col ${
                        hasError(meta) ? "justify-center" : "justify-between"
                      }`}>
                        <Text dataQa={`file-name-${languageCode}`}>
                          {input.value.name || input.value}
                        </Text>
                        {!hasError(meta) && (progress ? (
                          <Text
                            color="color-6"
                            dataQa={`file-kilobytes-${languageCode}`}
                          >
                            {`${Math.round((input.value.size * progress) / 100000)} KB / ${
                              Math.round(input.value.size / 1000)
                            } KB`}
                          </Text>
                        ) : actionButton)}
                      </div>
                    </div>
                    <AbortSymbol
                      size={xxl ? "16" : "14"}
                      className="cursor-pointer color-6"
                      onClick={removeFile}
                      data-qa={`file-remove-${languageCode}`}
                    />
                  </div>
                ) : (
                  <Dropzone
                    accept={accept}
                    onDrop={onDrop}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: `flex justify-center py-24 border rounded ${
                            (serverValidationError || (meta.dirty && meta.error))
                              ? "border-solid border-color-red"
                              : "border-dashed border-color-5"
                          }`
                        })}
                        data-qa={`file-upload-${languageCode}`}
                      >
                        <input {...getInputProps()}></input>
                        <Text dataQa={`file-upload-label-${languageCode}`}>
                          {i18nextTranslateDynamically(
                            i18nextKeys.uploadFile,
                            { extension: extension.toUpperCase() }
                          )}
                        </Text>
                      </div>
                    )}
                  </Dropzone>
                )}
                <Error
                  error={serverValidationError || (meta.dirty && meta.error)}
                  dataQa={`file-error-${languageCode}`}
                />
              </>
            );
          }}
        </MemoizedValidationField>
      )}
    </div>
  );
};

export default FileUpload;
