import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useHistory } from 'react-router-dom';
import api from "Api";
import { REDEMPTION_DOCUMENT_TEMPLATE, TENANT_FEATURE } from "Enums";
import PayoutMethodEnum from 'Enums/PayoutMethod';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import linkify from "Helpers/linkify";
import useFeatureAvailability from "Hooks/useFeatureAvailability";
import { Button } from "Components/shared/buttons";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Text from "Components/shared/Text";
import { UiContext } from "States/ui/uiState";
import useHandleError from "Utils/handleError";
import { ROUTES } from "Router/Routes";
import { SubsectionHeading, Templates } from "../shared";
import { updateTemplates } from "../shared/Templates";

const RedemptionConfig = () => {
  const [redemptionTypes, setRedemptionTypes] = useState(null);
  const [updating, setUpdating] = useState(false);
  const {
    breakpoints: { xxl }
  } = useContext(UiContext);
  const handleError = useHandleError();
  const history = useHistory();
  const {
    data: isRedemptionEnabled
  } = useFeatureAvailability.query({
    refetchOnMount: false,
    select: useCallback(features => features.some(feature =>
      feature.IsEnabled && feature.Type === TENANT_FEATURE.redeem
    ), []),
    onError: (error) => handleError({ error })
  });

  const contentWidth = xxl ? "700px" : "600px";

  useEffect(() => {
    const fetchMethods = async () => {
      try {
        const { value: redemptionMethodsConfig } = await api.Redemptions.getMethodsConfig();
        if (!redemptionMethodsConfig?.length) {
          history.replace(ROUTES.admin.config.tenant);
          return;
        }
        const types = {};
        redemptionMethodsConfig.forEach(({ Enabled, Type }) => {
          types[Type] = types[Type] || Enabled;
        });
        setRedemptionTypes(types);
      } catch (error) {
        handleError({ error });
      }
    };
    fetchMethods();
  }, []);

  const save = async (values, form) => {
    setUpdating(true);
    const { dirtyFields, initialValues } = form.getState();
    try {
      for (const updatedType of Object.keys(dirtyFields)  ) {
        await updateTemplates(values[updatedType], initialValues[updatedType], handleError);
      }
      form.restart(values);
    } catch (error) {
      handleError({ error });
    }
    setUpdating(false);
  };

  const insertAtMutator = ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, array => {
      const copy = [...(array || [])];
      copy.splice(index, 0, value);
      return copy;
    })
  };

  return (
    <div className="px-32 py-24">
      <div style={{ width: contentWidth }}>
        <div className="flex flex-col gap-16 xxl:gap-20 mb-40">
          <Text
            textStyle="h2"
            style={{
              scrollMargin: 60
            }}
            dataQa="title"
            uppercase
          >
            {i18nextTranslate(i18nextKeys.configRedemptionTitle)}
          </Text>
          <div data-qa="description">
            <Text
              textStyle="p4"
              dataQa="description"
              inline
            >
              {i18nextTranslate(i18nextKeys.configRedemptionDescription)}
            </Text>
          </div>
          <div className="flex flex-col gap-8">
            <Text
              textStyle="p2"
              dataQa="requirements-title"
            >
              {i18nextTranslate(i18nextKeys.configTemplatesRequirementsHeading)}
            </Text>
            <ol
              className="p4 color-8 text-paragraph list-inside"
              style={{ listStyleType: "lower-latin" }}
            >
              <li data-qa="requirement">{i18nextTranslate(i18nextKeys.configTemplatesRequirement)}</li>
              <li
                dangerouslySetInnerHTML={{
                  __html: linkify(i18nextTranslate(i18nextKeys.configRedemptionTemplateRules))
                }}
                data-qa="rules"
              />
            </ol>
          </div>
        </div>
        {redemptionTypes ? (
          <FinalForm
            onSubmit={save}
            subscription={{
              pristine: true,
              invalid: true
            }}
            mutators={{
              ...arrayMutators,
              insertAt: insertAtMutator
            }}
            render={({ handleSubmit, invalid, pristine }) => (
              <form>
                {Object.keys(redemptionTypes).map(Type => (
                  <div className="mb-40" key={Type}>
                    <SubsectionHeading
                      text={i18nextTranslate(i18nextKeys.configRedemptionTemplateHeading, {
                        name: PayoutMethodEnum.enumToFriendlyString(Type)
                      })}
                      dataQa={Type}
                    />
                    <Templates
                      fieldArrayName={Type}
                      fieldWidth={xxl ? "500px" : "404px"}
                      validationEnabled={isRedemptionEnabled && redemptionTypes[Type]}
                      templateType={REDEMPTION_DOCUMENT_TEMPLATE[Type]}
                    />
                  </div>
                ))}
                <Button
                  text={i18nextTranslate(i18nextKeys.configRedemptionButton)}
                  onClick={handleSubmit}
                  disabled={pristine || invalid || updating}
                  dataQa="submit"
                  secondary
                />
              </form>
            )}
          />
        ) : (
          <div className="flex justify-center px-32 py-24">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default RedemptionConfig;
