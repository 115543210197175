import React, { memo } from "react";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { PAYMENT_DOCUMENT_TEMPLATE } from "Enums";
import linkify from "Helpers/linkify";
import Text from "Components/shared/Text";
import { SubsectionHeading, Templates } from "Features/admin/ConfigManagement/shared";

const PurchaseTemplates = ({
  contentWidth,
  fieldWidth,
  paymentMethodEnabled,
  paymentMethodName,
  paymentMethodType
}) => (
  <div style={{ width: contentWidth }}>
    <div className="flex flex-col gap-16 xxl:gap-20 mb-20">
      <SubsectionHeading
        text={i18nextTranslate(i18nextKeys.configPaymentTemplateHeading, {
          context: paymentMethodType,
          name: paymentMethodName
        })}
        dataQa="template"
        className=""
      />
      <div data-qa="template-description">
        <Text
          textStyle="p4"
          inline
        >
          {i18nextTranslate(i18nextKeys.configPaymentTemplateDescription, {
            context: paymentMethodType
          })}
        </Text>
      </div>
      <div className="flex flex-col gap-8 mb-24">
        <Text
          textStyle="p2"
          dataQa="template-requirements-title"
        >
          {i18nextTranslate(i18nextKeys.configTemplatesRequirementsHeading)}
        </Text>
        <ol
          className="p4 color-8 text-paragraph list-inside"
          style={{ listStyleType: "lower-latin" }}
        >
          <li>{i18nextTranslate(i18nextKeys.configTemplatesRequirement)}</li>
          <li
            dangerouslySetInnerHTML={{
              __html: linkify(i18nextTranslate(i18nextKeys.configPaymentTemplateRules))
            }}
          />
          <li>{i18nextTranslate(i18nextKeys.configPaymentTemplateRequirement)}</li>
        </ol>
      </div>
    </div>
    <Templates
      fieldWidth={fieldWidth}
      validationEnabled={paymentMethodEnabled}
      templateType={PAYMENT_DOCUMENT_TEMPLATE[paymentMethodType]}
    />
  </div>
);

export default memo(PurchaseTemplates);
