const ADMIN_ROUTES = {
  base: '/admin',
  redemptions: '/admin/redemptions',
  redemptionDetails: '/admin/redemptions/:id',
  orders: '/admin/orders',
  orderDetails: '/admin/orders/:id',
  kyc: '/admin/kyc',
  config: {
    base: '/admin/config',
    json: '/admin/json-config',
    section: '/admin/config/:section',
    tenant: '/admin/config/tenant',
    purchase: '/admin/config/purchase',
    collection: '/admin/config/collection/:type',
    payment: '/admin/config/payment/:type',
    redemption: '/admin/config/redemption'
  }
};

export const ROUTES = {
  base: '/',
  welcome: '/welcome',
  about: '/about',
  imprint: '/imprint',
  account: '/account',
  featuredAssetBase: '/featured',
  featuredAsset: '/featured/:id',
  shop: '/shop',
  cart: '/cart',
  login: '/login',
  signup: '/signup',
  confirmEmail: '/confirm-email',
  verifyProfile: '/verify-profile',
  blocked: '/blocked',
  logout: '/logout',
  redemption: '/redemption',
  redemptionForm: '/redemption/details/:id',
  redemptionSummary: '/redemption/summary/:id',
  redemptionQR: '/redemption/qr',
  redemptionError: '/redemption/error',
  checkout: '/checkout',
  checkoutCollectionMethod: '/checkout/method',
  checkoutCustomerDetails: '/checkout/details/customer',
  checkoutOrderSummary: '/checkout/details/order',
  checkoutPaymentMethod: '/checkout/details/payment',
  redemptionFinish: '/redemption/finish',
  checkoutPending: '/checkout/pending',
  checkoutFinish: '/checkout/finish',
  checkoutPendingBankTransfer: '/checkout/pending/bank-transfer',
  checkoutFinishBankTransfer: '/checkout/finish/bank-transfer',
  checkoutPendingExpired: '/checkout/pending/expired',
  checkoutFinishExpired: '/checkout/finish/expired',
  orders: '/history/orders',
  orderDetails: '/history/orders/:id',
  redemptions: '/history/redemptions',
  redemptionDetails: '/history/redemptions/:id',
  kyc: '/kyc',
  error: '/error',
  terms: '/terms-and-conditions',
  admin: ADMIN_ROUTES
};
