import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { ROUTES } from 'Router/Routes';
import { KYC_PROVIDER } from 'Enums/KycProvider';
import { Checkbox } from 'Components/shared/formElements';
import { Button } from 'Components/shared/buttons';
import { SingleChevron } from 'Components/shared/symbols';
import ConfirmationModal from 'Components/shared/ConfirmationModal';
import Text from 'Components/shared/Text';
import Tooltip from 'Components/shared/Tooltip';
import useHandleError from 'Utils/handleError';
import { DIRECTION } from 'Helpers/icons';
import { getKycTableData } from 'Helpers/kyc';
import api from 'Api';
import KycProviderRedirectionModal from './KycProviderRedirectionModal';
import './KycTiersTable.css';

const KycTiersTable = ({
  currentKycTier,
  isKycCaseOpen,
  pendingKycTier,
  kycFeatures,
  paymentMethods,
  redemptionMethods,
  tierList,
  assets,
  defaultLanguageCode
}) => {
  const [showRedirectionModal, setShowRedirectionModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { config } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const kycFeaturesTier = useMemo(() => getKycTableData({
    kycFeatures,
    assets,
    paymentMethods,
    redemptionMethods,
    currentLanguageCode: lang,
    defaultLanguageCode
  }), [kycFeatures, assets, paymentMethods, redemptionMethods, lang, defaultLanguageCode]);
  const {
    breakpoints: { sm, md, xl },
  } = useContext(UiContext);
  const tierColumnFlex = sm ? '1 0 max(20%, 120px)' : '0 0 100%';
  const handleError = useHandleError();
  const history = useHistory();
  const location = useLocation();
  const isMaxTier = tierList.length - 1 === currentKycTier;

  const requestTier = async (tierNumber) => {
    const provider = config.kycTiersDescription[tierNumber]?.providers?.[0].name;
    if (provider !== KYC_PROVIDER.manual) {
      setShowRedirectionModal(true);
    }
    try {
      const { VerificationLink } = await api.Kyc.createCase({
        Provider: provider,
        TierNumber: tierNumber,
        RedirectUrl: location.state?.returnUrl || window.location.origin
      });
      if (VerificationLink) {
        window.open(VerificationLink, '_self', 'noopener,noreferrer');
      } else {
        setShowConfirmationModal(true);
      }
    } catch (error) {
      setShowRedirectionModal(false);
      const message = i18nextTranslate(i18nextKeys.errorKycCreateCase);
      handleError({ error, message });
    }
  };

  const redirectToAccount = () => history.push(ROUTES.account);

  const syncScroll = ({ target }) => {
    document.getElementById('kycTiersTableHeader').scrollLeft =
      target.scrollLeft;
    document.getElementById('kycTiersTableFooter').scrollLeft =
      target.scrollLeft;
  };

  const scrollWithButtons = (isScrollRight) => {
    const kycTierColumnWidth = document.getElementById('kycTierColumn')
      .offsetWidth;
    const kycTiers = document.getElementById('kycTiers');
    kycTiers.scrollBy({
      left: kycTierColumnWidth * (isScrollRight ? 1 : -1),
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="mt-16 relative">
        <div className="sticky top-0 z-10 h-24 bg-2"></div>
        <div className="flex bg-3" data-qa="kycTiersTable">
          <div
            className="relative"
            style={{
              flex: `0 0 ${md ? '230px' : '188px'}`,
            }}
          >
            <div
              className="sticky bg-3"
              style={{ top: '24px' }}
            >
              <div className="flex items-center h-48 px-16 bg-4_3">
                <Text textStyle="h3">
                  {i18nextTranslate(i18nextKeys.kycTiersHeader)}
                </Text>
              </div>
            </div>
            {kycFeaturesTier.map(({ feature }, index) => (
              <div
                key={index}
                className={`flex items-center h-48 px-16 ${index % 2
                  ? 'bg-4_3'
                  : 'bg-4_10'
                  }`}
              >
                <Text
                  textStyle="h3"
                  color="color-6"
                  showNumberOfLines="2"
                  dataQa={`kycTiersTable-feture-${index}-description`}
                >
                  {feature}
                </Text>
              </div>
            ))}
            {!isMaxTier && (
              <div
                className={`sticky z-10 bottom-0 flex items-center h-48 px-16 ${isKycCaseOpen
                  ? 'bg-4--20'
                  : 'bg-3-10'
                  }`}
              >
                <Text textStyle="h3">
                  {i18nextTranslate(
                    isKycCaseOpen
                      ? i18nextKeys.kycProcessingMessage
                      : i18nextKeys.kycTierUpgrade
                  )}
                </Text>
              </div>
            )}
            {xl && (
              <div
                className={`firefox-scrollbar-hack ${isKycCaseOpen
                  ? 'bg-4--20'
                  : 'bg-3-10'
                  }`}
              ></div>
            )}
          </div>
          <div
            className="flex-auto flex flex-col overscroll-contain color-8"
            style={{
              // TODO: a hack for smaller screens, find a better solution
              maxWidth: `calc(100vw - 220px)`,
              ...(md && { maxWidth: `calc(100% - 230px)` }),
            }}
          >
            <div className="sticky z-10" style={{ top: '24px' }}>
              {!sm && (
                <div
                  id="kycTierColumn"
                  className="absolute h-full w-full flex justify-between items-center color-6"
                  data-qa="kycTiersTable-mobileButtons-scroll"
                >
                  <div className="z-10 h-full flex items-center bg-3 px-4">
                    <SingleChevron
                      direction={DIRECTION.left}
                      size="14"
                      className="cursor-pointer"
                      onClick={() => scrollWithButtons(false)}
                      data-qa="kycTiersTable-mobileButtons-scrollLeft"
                    />
                  </div>
                  <div className="z-10 h-full flex items-center bg-3 px-4">
                    <SingleChevron
                      direction={DIRECTION.right}
                      size="14"
                      className="cursor-pointer"
                      onClick={() => scrollWithButtons(true)}
                      data-qa="kycTiersTable-mobileButtons-scrollRight"
                    />
                  </div>
                </div>
              )}
              <div className="flex overflow-hidden" id="kycTiersTableHeader">
                {tierList.map(({ Number, Name, Description }) => (
                  <div
                    key={Number}
                    className="flex flex-col justify-center items-center h-48 text-xs leading-4 bg-3 relative"
                    style={{
                      flex: `${tierColumnFlex}`,
                    }}
                  >
                    <Tooltip
                      text={Description}
                      dataQa={`kycTiersTable-tier-${Number}`}
                      useIcon
                    />
                    <Text
                      textStyle="h3"
                      className="mt-8"
                      dataQa={`kycTiersTable-tier-${Number}-name`}
                      uppercase
                    >
                      {`${Number}. ${Name}`}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="flex overlay-kyc-tiers custom-scrollbar"
              onScroll={syncScroll}
              id="kycTiers"
            >
              {tierList.map(({ Number }) => (
                <div key={Number} style={{ flex: `${tierColumnFlex}` }}>
                  {kycFeaturesTier.map(({ kycTier }, index) => (
                    <div
                      key={index}
                      className={`p-16 h-48 flex justify-center ${index % 2
                        ? 'bg-3'
                        : 'bg-4_7'
                        }`}
                    >
                      <Checkbox
                        checked={kycTier <= Number}
                        dataQa={`kycTiersTable-tier-${kycTier}-feature-${Number}-checkbox`}
                        readOnly
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {!isMaxTier && (
              <div
                className="flex sticky bottom-0 z-10 overflow-hidden"
                id="kycTiersTableFooter"
              >
                {tierList.map(({ Number }) => (
                  <div
                    key={Number}
                    className={`h-48 px-16 py-12 flex justify-center items-center ${isKycCaseOpen
                      ? 'bg-4--20'
                      : 'bg-3-10'
                      }`}
                    style={{ flex: `${tierColumnFlex}` }}
                  >
                    {(isKycCaseOpen ? pendingKycTier === Number : Number > currentKycTier) && (
                      <Button
                        text={
                          isKycCaseOpen
                            ? i18nextTranslate(i18nextKeys.kycProcessing)
                            : i18nextTranslateDynamically(
                              i18nextKeys.kycGetTier,
                              { tier: Number }
                            )
                        }
                        onClick={() => requestTier(Number)}
                        dataQa={`kycTiersTable-tier-${Number}-button`}
                        width={{
                          xxl: "125px",
                          xl: "104px",
                          default: "96px"
                        }}
                        disabled={isKycCaseOpen}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <KycProviderRedirectionModal
        show={showRedirectionModal}
        close={() => setShowRedirectionModal(false)}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
        title={i18nextTranslate(i18nextKeys.kycManualConfirmationHeading)}
        description={i18nextTranslate(i18nextKeys.kycManualConfirmationText)}
        confirmButtonText={i18nextTranslate(i18nextKeys.buttonOk)}
        onConfirm={redirectToAccount}
        showCloseButton={false}
      />
    </>
  );
};

export default KycTiersTable;
