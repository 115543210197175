import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { getTranslation } from './translations';

export const getKycTableData = ({
  kycFeatures = [],
  assets = [],
  paymentMethods = [],
  redemptionMethods = [],
  currentLanguageCode,
  defaultLanguageCode
}) => {
  console.log("call")
  let data = [];

  const purchaseKyc = kycFeatures.find(
    ({ Name }) => Name === "purchase.availability"
  );
  data.push({
    feature: i18nextTranslate(i18nextKeys.kycTiersAvailabilityPurchase),
    kycTier: purchaseKyc?.RequiredKycTier || 0,
  });
  
  if (redemptionMethods.length) {
    const redemptionKyc = kycFeatures.find(
      ({ Name }) => Name === "redemption.availability"
    );
    data.push({
      feature: i18nextTranslate(i18nextKeys.kycTiersAvailabilityRedemption),
      kycTier: redemptionKyc?.RequiredKycTier || 0,
    });
  }

  for (const paymentMethod of paymentMethods) {
    if (!paymentMethod.IsEnabled) {
      continue;
    }
    const paymentMethodTranslationKey = `kycTiersPaymentMethod${paymentMethod.Type}`;
    const feature = i18nextTranslateDynamically(
      i18nextKeys.kycTiersPaymentMethodTemplate,
      {
        paymentMethod: i18nextTranslate(
          i18nextKeys[paymentMethodTranslationKey]
        ),
      }
    );
    data.push({
      feature,
      kycTier: paymentMethod.KYCTier,
    });
  }

  if (!redemptionMethods.length) {
    return data;
  }

  for (const method of redemptionMethods) {
    const { Enabled, Type, Id, UserGets, UserPays, Translations } = method;
    if (!Enabled) {
      continue;
    }
    const asset = assets.find(({ Id }) => UserPays.UniqueAssetId === Id);
    const methodName = getTranslation("Name", currentLanguageCode, defaultLanguageCode, Translations);
    const feature = i18nextTranslateDynamically(
      i18nextKeys.kycTiersRedemptionTemplate,
      {
        userPaysAsset: asset?.Name,
        userGetsUOM: UserGets.Uom,
        methodName
      }
    );
    const matchingFeature = kycFeatures.find(
      ({ Name }) => Name === `redemption.methods.${Type}.${Id}`
    );
    data.push({
      feature,
      kycTier: matchingFeature?.RequiredKycTier || 0,
    });
  }

  return data;
};
