import React, { useRef } from 'react';
import {
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation
} from 'react-router-dom';
import { ROUTES } from 'Router/Routes';
import { CONFIG_SECTION } from 'Enums';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import TabBar from 'Components/shared/TabBar/TabBar';
import TabBarItem from 'Components/shared/TabBar/TabBarItem';
import Header from 'Components/shared/Header';
import TenantConfig from './TenantConfig';
import PurchaseConfig from './PurchaseConfig';
import CollectionMethod from './PurchaseConfig/CollectionMethods';
import PaymentMethod from './PurchaseConfig/PaymentMethods';
import RedemptionConfig from './RedemptionConfig/RedemptionConfig';

// TODO: As wished by management, the other items must be commented out
// until they have been implemented.
const TabBarItems = [
  {
    title: i18nextKeys.configManagementMenuTenantConfiguration,
    dropdownItems: {
      general: i18nextKeys.tenantSettingsGeneralSettingsTitle,
      languagesAndCurrencies: i18nextKeys.tenantSettingsLanguagesAndCurrenciesTitle,
      // branding: i18nextKeys.tenantSettingsBrandingSettingsTitle
    },
    dataQa: CONFIG_SECTION.tenant,
    path: ROUTES.admin.config.tenant,
    paths: []
  },
  {
    title: i18nextKeys.configManagementMenuPurchaseConfiguration,
    dataQa: CONFIG_SECTION.purchase,
    path: ROUTES.admin.config.purchase,
    paths: [ ROUTES.admin.config.collection, ROUTES.admin.config.payment ]
  },
  {
    title: i18nextKeys.configManagementMenuRedemptionMethods,
    dataQa: CONFIG_SECTION.redemption,
    path: ROUTES.admin.config.redemption,
    paths: []
  }
  /* {
    title: i18nextKeys.configManagementMenuAvailableAssets
  },
  {
    title: i18nextKeys.configManagementMenuPurchaseConfiguration,
    dropdownItems: [
      i18nextKeys.purchaseConfigurationAssetsForPurchasingTitle,
      i18nextKeys.purchaseConfigurationPaymentConfigurationsTitle
    ],
  },
  {
    title: i18nextKeys.configManagementMenuKyc
  }, */
];

const ConfigManagement = () => {
  const history = useHistory();
  const location = useLocation();
  const generalSettingsRef = useRef(null);
  const languagesAndCurrenciesRef = useRef(null);

  const openSection = (path) => {
    history.push(path);
  };

  return (
    <div className="color-8">
      <Header text={i18nextTranslate(i18nextKeys.configManagementHeader)} />
      <div
        className="bg-3 rounded"
      >
        <TabBar className="px-32">
          {TabBarItems.map((item) => (
            <TabBarItem
              key={item.dataQa}
              title={item.title}
              onClick={() => openSection(item.path)}
              dataQa={`tabBar-${item.dataQa}`}
              dropdownOptions={item.dropdownItems}
              dropdownItemOnClick={(e) => {
                switch (e) {
                  case i18nextKeys.tenantSettingsGeneralSettingsTitle:
                    generalSettingsRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                    break;
                  case i18nextKeys.tenantSettingsLanguagesAndCurrenciesTitle:
                    languagesAndCurrenciesRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                    break;
                }
              }}
              active={!!matchPath(location.pathname, [ item.path, ...item.paths ])}
            />
          ))}
        </TabBar>
        <Switch>
          <Route
            path={ROUTES.admin.config.tenant}
            exact
          >
            <TenantConfig
              generalSettingsInnerRef={generalSettingsRef}
              languagesAndCurrenciesInnerRef={languagesAndCurrenciesRef}
            />
          </Route>
          <Route
            path={ROUTES.admin.config.purchase}
            component={PurchaseConfig}
            exact
          />
          <Route
            path={ROUTES.admin.config.collection}
            component={CollectionMethod}
            exact
          />
          <Route
            path={ROUTES.admin.config.payment}
            component={PaymentMethod}
            exact
          />
          <Route
            path={ROUTES.admin.config.redemption}
            component={RedemptionConfig}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

export default ConfigManagement;
