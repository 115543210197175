import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import api from 'Api';
import usePostLoginRedirect from "Hooks/usePostLoginRedirect";
import useHandleError from 'Utils/handleError';
import { REFERRAL_CODE_STATE, STORAGE_KEYS } from 'Enums';
import { ROUTES } from 'Router/Routes';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import { EmailIcon } from 'Components/shared/symbols';
import { Button, TextButton } from 'Components/shared/buttons';
import SimplePage from 'Components/shared/SimplePage';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const EmailConfirmation = () => {
  const redirect = usePostLoginRedirect();
  const handleError = useHandleError();
  const history = useHistory();
  const {
    checkEmailConfirmation,
    isEmailConfirmed,
    getStatus,
    loading,
    user
  } = useContext(AuthContext);
  const {
    config: {
      checkoutSettings,
      fullNameWelcome
    }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const resendInterval = useRef(null);

  const setTimer = (timeout = 60) => {
    setRemainingSeconds(timeout);
    localStorage.setItem(
      STORAGE_KEYS.emailResendTimeout,
      Date.now() + timeout * 1000
    );
    resendInterval.current = setInterval(() => {
      setRemainingSeconds((remainingSeconds) => {
        if (remainingSeconds === 0) {
          return remainingSeconds;
        }
        if (remainingSeconds - 1 === 0) {
          clearInterval(resendInterval.current);
          localStorage.removeItem(STORAGE_KEYS.emailResendTimeout);
          return 0;
        }
        return remainingSeconds - 1;
      });
    }, 1000);
  }

  const resendEmail = async () => {
    try {
      const isSent = await api.User.resendConfirmationEmail();
      if (isSent) {
        setTimer();
      }
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorSignup);
      handleError({ error, message });
    }
  };

  useEffect(() => {
    const handleRedirection = async () => {
      try {
        const {
          IsActive,
          ReferralCodeState
        } = await api.User.getRegistrationTemplate();
        if (IsActive && ReferralCodeState !== REFERRAL_CODE_STATE.Disabled) {
          history.replace(ROUTES.signup);
          return;
        }
        await api.User.signUp({ Language: lang });
        const status = await getStatus();
        redirect({
          status,
          isEmailConfirmed: true,
          hideShop: checkoutSettings.hideShop
        });
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.errorSignup);
        handleError({ error, message });
      }
    };
    if (isEmailConfirmed) {
      handleRedirection();
    }
  }, [isEmailConfirmed]);

  useEffect(() => {
    const existingTimeout = localStorage.getItem(STORAGE_KEYS.emailResendTimeout);
    localStorage.removeItem(STORAGE_KEYS.emailResendTimeout);
    const remainingSeconds = existingTimeout
      ? Math.round((existingTimeout - Date.now()) / 1000)
      : 0;
    if (remainingSeconds > 5) {
      setTimer(remainingSeconds);
    }
    return () => {
      if (resendInterval.current) {
        clearInterval(resendInterval.current);
      }
    };
  }, []);

  return (
    <SimplePage
      header={i18nextTranslate(i18nextKeys.signupTitle)}
      icon={EmailIcon}
      title={i18nextTranslate(
        i18nextKeys.signupWelcome,
        { name: `${user.profile.given_name}${
          fullNameWelcome ? " " + user.profile.family_name : ""}` }
      )}
      description={i18nextTranslate(
        i18nextKeys.signupEmailDescription,
        { email: user.profile.email }
      )}
      dataQa="email-confirmation"
    >
      <div className="flex flex-col items-center gap-40 mt-16">
        <TextButton
          dataQa="button-resend"
          text={remainingSeconds
            ? i18nextTranslate(
              i18nextKeys.signupEmailCooldown,
              { seconds: remainingSeconds }
            )
            : i18nextTranslate(i18nextKeys.signupEmailResend)
          }
          onClick={resendEmail}
          disabled={remainingSeconds || loading}
        />
        <Button
          dataQa="button-confirm"
          text={i18nextTranslate(i18nextKeys.signupEmailConfirm)}
          onClick={() => checkEmailConfirmation()}
          width={{
            xxl: '190px',
            default: '170px'
          }}
          disabled={loading}
          loading={loading}
          secondary
        />
      </div>
    </SimplePage>
  );
};

export default EmailConfirmation;
