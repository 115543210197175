import { matchPath, useHistory, useLocation } from 'react-router-dom';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { STORAGE_KEYS, TENANT_FEATURE, USER_STATUS } from 'Enums';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import { useContext } from 'react';
import { EnvContext } from 'States/env/envState';
import { ROUTES } from 'Router/Routes';

export const getPreferedRedirectPath = (features = null, hideShop = false) => {
  const returnUrl = sessionStorage.getItem(STORAGE_KEYS.authReturnPath);
  if (returnUrl) {
    return returnUrl;
  }
  if (!features) {
    return ROUTES.base;
  }
  if (isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset)) {
    return ROUTES.featuredAssetBase;
  }
  if (!hideShop && isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)) {
    return ROUTES.shop;
  }
};

export const getAccountRedirectPath = (status, isEmailConfirmed = true) => {
  switch (status) {
    case USER_STATUS.New:
      return isEmailConfirmed
        ? ROUTES.signup
        : ROUTES.confirmEmail;
    case USER_STATUS.Unconfirmed:
    case USER_STATUS.Confirmed:
      return isEmailConfirmed
        ? ROUTES.verifyProfile
        : ROUTES.confirmEmail;
    case USER_STATUS.Approved:
      return isEmailConfirmed
        ? null
        : ROUTES.confirmEmail;
    case USER_STATUS.Rejected:
    case USER_STATUS.Disabled:
      return ROUTES.blocked;
  };
};

const usePostLoginRedirect = () => {
  const { env } = useContext(EnvContext);
  const { data: features } = useFeatureAvailability.query({
    queryFnArgs: [env?.TenantId],
    enabled: !!env
  });
  const history = useHistory();
  const location = useLocation();

  const postLoginRedirect = ({
    status,
    isEmailConfirmed = false,
    isAuthRedirect = false,
    hideShop = false
  }) => {
    const pathname = getAccountRedirectPath(status, isEmailConfirmed);
    if (pathname) {
      history.replace(pathname, { previous: location.pathname });
      return;
    }
    if (isAuthRedirect || matchPath(location.pathname, [
      ROUTES.signup,
      ROUTES.confirmEmail,
      ROUTES.verifyProfile,
      ROUTES.blocked
    ])) {
      history.replace(getPreferedRedirectPath(features, hideShop));
    }
  };

  return postLoginRedirect;
};

export default usePostLoginRedirect;
