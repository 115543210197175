import { PAYMENT_METHOD } from "./PaymentMethod";
import {
  BANK_TRANSFER,
  BITCOIN,
  ERC20,
  ETH,
  PHYSICAL
} from './RedemptionMethodTypes';

export const PAYMENT_DOCUMENT_TEMPLATE = {
  [PAYMENT_METHOD.None]: 'None',
  [PAYMENT_METHOD.CreditCard]: 'OrderConfirmationByCreditCard',
  [PAYMENT_METHOD.CryptoCurrency]: 'OrderConfirmationByCryptoCurrency',
  [PAYMENT_METHOD.BankTransfer]: 'OrderBankTransferInvoice' 
};

export const REDEMPTION_DOCUMENT_TEMPLATE = {
  [BANK_TRANSFER]: 'RedemptionConfirmationByBankTransfer',
  [BITCOIN]: 'RedemptionConfirmationByBitcoinPayment',
  [ERC20]: 'RedemptionConfirmationByEthereumToken',
  [ETH]: 'RedemptionConfirmationByEthereum',
  [PHYSICAL]: 'RedemptionConfirmationByPhysicalDelivery'
};
