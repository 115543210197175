import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LogOnMount } from 'react-amplitude-hooks';
import api from 'Api';
import useHandleError from 'Utils/handleError';
import { getTranslation } from 'Helpers/translations';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import Fade from 'Components/shared/Fade';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import SignupReminder from 'Features/Signup/SignupReminder';
import { UiContext } from 'States/ui/uiState';
import useConfigSettings from 'Hooks/useConfigSettings';
import RedemptionMethodCard from './RedemptionMethodCard';

const RedeemMethodSelection = () => {
  const { isActive, isBlocked } = useContext(AuthContext);
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const {
    config: {
      redeemSettings: {
        methodSelectionDescription
      }
    }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const handleError = useHandleError();
  const [redemptionMethods, setRedemptionMethods] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: defaultLanguageCode } = useConfigSettings.query({
    select: useCallback(({ Languages }) => {
      const language = Languages.find(({ IsDefault }) => IsDefault)
      return language?.Code
    }, [])
  });

  const getConfigTranslation = (translations) => translations[lang] || translations[defaultLanguageCode] ||
    translations["en"] || Object.values(translations).find(translation => !!translation);

  useEffect(() => {
    const getRedemptionMethods = async () => {
      try {
        let updatedMethods = [];
        const { value: redemptionMethodsConfig } = await api.Redemptions.getEnabledMethodsConfig();
        if (!redemptionMethodsConfig?.length) {
          return;
        }
        const { value: assetsData } = await api.Assets.getAssets();
        for (const methodConfig of redemptionMethodsConfig) {
          const {
            Name,
            UnitOfMeasureCode,
            CurrencyCode,
            Translations
          } = assetsData.find(
            ({ Id }) => methodConfig.UserPays.UniqueAssetId === Id
          );
          const initialTranslation = getTranslation("Name", lang, defaultLanguageCode, Translations, true) || { Name };
          if (initialTranslation) {
            updatedMethods.push({
              ...methodConfig,
              UserPays: {
                ...methodConfig.UserPays,
                initialTranslation,
                UnitOfMeasureCode,
                CurrencyCode,
                Translations
              }
            });
          }
        }
        updatedMethods.sort((a, b) =>
          a.UserPays?.initialTranslation.Name.localeCompare(b.UserPays.initialTranslation.Name, lang)
        );
        setRedemptionMethods(updatedMethods);
        setLoading(false);
      } catch (error) {
        handleError({ error });
      }
    }
    if (defaultLanguageCode) {
      getRedemptionMethods();
    }
  }, [defaultLanguageCode]);

  return (
    <LogOnMount eventType="Redemption opened">
      {loading ? (
        <div className="flex justify-center">
          <LoadingSpinner dataQa="loading-page" />
        </div>
      ) : (
        <>
          <Text
            className="mb-16 md:mb-24"
            dataQa="redemption-description"
          >
            {getConfigTranslation(methodSelectionDescription)}
          </Text>
          <Fade show={true}>
            <div
              data-qa="redeem-method"
              className="grid row-gap-32 col-gap-40 justify-center"
              style={{
                gridTemplateColumns: md
                  ? 'repeat(auto-fit, 200px)'
                  : 'repeat(auto-fit, 100%)',
                gridAutoRows: '1fr',
              }}
            >
              {redemptionMethods.map((redemptionMethod) => (
                <RedemptionMethodCard
                  key={redemptionMethod.Id}
                  redemptionMethod={redemptionMethod}
                  defaultLanguageCode={defaultLanguageCode}
                />
              ))}
            </div>
          </Fade>
          {!isActive && !isBlocked && (
            <SignupReminder text={i18nextTranslate(i18nextKeys.signupNotificationRedemption)} />
          )}
        </>
      )}
    </LogOnMount>
  );
};

export default RedeemMethodSelection;
